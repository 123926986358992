/* LIST PAGES ROUTES */
export const mainPageRoute = '/zaimy/:paramRegion?/';
export const naKartuPageRoute = '/zaimy/na-kartu/';
export const naKartuRegionPageRoute = '/zaimy/na-kartu/:paramRegion/';
export const onlinePageRoute = '/zaimy/mfo-online/';
export const newMicrocreditsPageRoute = '/zaimy/novinki/';
export const calculatorPageRoute = '/zaimy/kalkuljator/';
export const kzContextPageRoute = '/kz/zaimy/predlozheniya/';
/* LANDING PAGES ROUTES */
export const landingPageRoute = '/zaimy/lushie_zaimy_61_dney_i_bolee/';
export const brandingPageRoute = '/zaimy/lushie_zaimy_61_dney_i_bolee_s_novim_mfo/';
export const promotionPageRoute = '/zaimy/akcii/';
export const partnersPageRoute = '/zaimy/partners/';
export const leadSuPageRoute = '/zaimy/lushie_zaimy_bistro/';
export const guruleadsPageRoute = '/zaimy/lushie_zaimy_bystro/';
export const newSpecOffersLandingPageRoute = '/zaimy/new_special/';
export const specOffersLandingPageRoute = '/zaimy/special/';
export const fullDealLandingPageRoute = '/zaimy/full-deal-offer/';
export const octoberPrizeDrawLandingPageRoute = '/zaimy/october-prize-draw/';

export const newSpecOffersLandingPageRouteWithSpecificAffIdAndHub = '/zaimy/new_special/affho/:affId/hub/:hub';

export const partner2GisPageRoute = '/zaimy/partners/2gis/';

export const specOffersLandingPageRouteWithSpecificAffId = '/zaimy/special/affho/:affId';
export const specOffersLandingPageRouteWithSpecificAffIdAndAffSub2 =
  '/zaimy/special/affho/:aff_id/hub/:aff_sub2([0-9]+[0-9\\.]*)';

/* SPECIAL MFO ROUTES */
export const specOffersLandingPageRouteWebbankirWb1 = '/zaimy/special/webbankir/wb1/';
export const specOffersLandingPageRouteWebbankirWb2 = '/zaimy/special/webbankir/wb2/';
export const specOffersLandingPageRouteBistrodengi = '/zaimy/special/bistrodengi/';
export const specOffersLandingPageRouteTurbozaim = '/zaimy/special/turbozaim/';

export const specialMfoRoutes = [
  specOffersLandingPageRouteWebbankirWb1,
  specOffersLandingPageRouteWebbankirWb2,
  specOffersLandingPageRouteBistrodengi,
  specOffersLandingPageRouteTurbozaim,
] as const;

/* PRODUCT PAGES */
export const productPageRoute = '/zaimy/mfo/:organizationAlias/mikrokredit/:productAlias/';
export const oldProductPageRoute = '/mikrofinansovaja-organizacija/:organizationAlias/mikrokredit/:productAlias/';
export const productDetailsRoute = '/zaimy/mfo/:organizationAlias/:productAlias/';

export const megafonPageRoutes = ['/zaimy/1f_good/', '/zaimy/1f_fix/'];

/**
 * контекстная витрина CRM
 */
export const crmContextPageRoute = '/zaimy/predlozheniya-s-nizkim-srokom/';
/* MICROCREDIT SELECTION ROUTES */
export const microcreditSelectionRoute = '/zaimy/onlain/';

/* INTERNAL ROUTES */
export const testCheckRoute = '/zaimy/test';
export const bundleAnalyzeRoute = '/internal/bundle-analyze';

/* ROUTE FOR ANDROID WEB VIEW */
export const whiteLabelRoute = '/zaimy/wl_app/';

export const promoTermsRoute = '/zaimy/promo-terms/';
export const appLandingRoute = '/zaimy/app/';

export const webWhiteLabelDeniedRoute = '/zaimy/wl_app/other_web/';
export const appWhiteLabelDeniedRoute = '/zaimy/wl_app/other/';
export const whiteLabelDeniedListRoutes = [appWhiteLabelDeniedRoute, webWhiteLabelDeniedRoute];

export const mfoAndroidRoute = '/promo/mfo_android/';

/* Pod PTS ROUTES */
export const podPtsListRoutes: readonly string[] = [
  '/zaimy/pod-pts/',
  '/zaimy/pod-pts/:paramRegion/',
  '/zaimy/pod-pts-avtolombardy/',
  '/zaimy/pod-pts-avtolombardy/:paramRegion/',
  '/zaimy/pod-pts-bez-avto/',
  '/zaimy/pod-pts-bez-avto/:paramRegion/',
  '/zaimy/bez-pts/',
  '/zaimy/pod-pts-po-doverennosti/',
  '/zaimy/pod-zalog-pts-jur-licam/',
];

/* SEO ROUTES */
export const seoListRoutes: readonly string[] = [
  '/zaimy/1000-na-kivi/',
  '/zaimy/2000-na-kivi/',
  '/zaimy/24-chasa-onlayn/',
  '/zaimy/300-na-kivi/',
  '/zaimy/3000-na-kivi/',
  '/zaimy/500-na-kivi/',
  '/zaimy/5000-na-kivi/',
  '/zaimy/50000-na-kivi/',
  '/zaimy/70-let/',
  '/zaimy/75-let/',
  '/zaimy/80-let/',
  '/zaimy/85-let/',
  '/zaimy/besplatno/',
  '/zaimy/bez-documentov/',
  '/zaimy/bez-elektronnoj-pochty/',
  '/zaimy/bez-foto/',
  '/zaimy/bez-karty/',
  '/zaimy/bez-kreditnoy-istorii/',
  '/zaimy/bez-obmana/',
  '/zaimy/bez-otkaza-100-procentov-odobreniya/',
  '/zaimy/bez-otkaza/',
  '/zaimy/bez-pasporta/',
  '/zaimy/bez-podpisok/',
  '/zaimy/bez-podtverzhdenija-lichnosti/',
  '/zaimy/bez-poruchiteley-i-spravok/',
  '/zaimy/bez-posrednikov/',
  '/zaimy/bez-predoplaty/',
  '/zaimy/bez-propiski-i-vremennoy-registracii/',
  '/zaimy/bez-protsentov-na-30-dnej/',
  '/zaimy/bez-protsentov/',
  '/zaimy/bez-proverok/',
  '/zaimy/bez-snilsa/',
  '/zaimy/bez-spisanija/',
  '/zaimy/bez-spravok-o-dohodah/',
  '/zaimy/bez-telefona/',
  '/zaimy/bezotkazanye/',
  '/zaimy/bezrabotnym-na-kivi/',
  '/zaimy/bezrabotnym-s-plohoi-ki/',
  '/zaimy/bezrabotnym/',
  '/zaimy/bolshie-zaimy/',
  '/zaimy/cherez-gosuslugi/',
  '/zaimy/dengi-v-dolg-bez-otkaza/',
  '/zaimy/dengi-v-dolg-bez-proverki-ki/',
  '/zaimy/dengi-v-dolg-na-kivi/',
  '/zaimy/dengi-v-dolg-v-20-let/',
  '/zaimy/dengi-v-rassrochku-bez-procentov/',
  '/zaimy/dlja-ip/',
  '/zaimy/dlja-pogashenija/',
  '/zaimy/dlya-grazhdan-sng/',
  '/zaimy/dolg-na-kartu-sberbanka/',
  '/zaimy/dolgosrochnye/',
  '/zaimy/dolzhnikam/',
  '/zaimy/ehkspress-na-kartu/',
  '/zaimy/inostrancam/',
  '/zaimy/juridicheskim-licam/',
  '/zaimy/kalkuljator-microzaimov/',
  '/zaimy/kivi-yandex/',
  '/zaimy/kratkosrochnye/',
  '/zaimy/kruglosutochno/',
  '/zaimy/legkie/',
  '/zaimy/maloizvestnye-mfo/',
  '/zaimy/mfo-bez-proverki-kreditnoy-istorii/',
  '/zaimy/mgnovennye/',
  '/zaimy/mikro/',
  '/zaimy/mikrozaymy-dlya-biznesa/',
  '/zaimy/mini-onlajn/',
  '/zaimy/mkk/',
  '/zaimy/momentalny/',
  '/zaimy/na-1-mesjac/',
  '/zaimy/na-10-dnej/',
  '/zaimy/na-2-goda/',
  '/zaimy/na-2-mesjaca/',
  '/zaimy/na-3-mesjaca/',
  '/zaimy/na-4-mesjaca/',
  '/zaimy/na-60-dnej/',
  '/zaimy/na-bankovskiy-schet/',
  '/zaimy/na-chuzhuju-kartu/',
  '/zaimy/na-contact/',
  '/zaimy/na-debetovuju-kartu/',
  '/zaimy/na-dom/',
  '/zaimy/na-elektronnyy-koshelek/',
  '/zaimy/na-god-12-mesyacev/',
  '/zaimy/na-kartu-bez-otkazov/',
  '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/',
  '/zaimy/na-kartu-bez-proverok-i-zvonkov/',
  '/zaimy/na-kartu-do-zarplaty/',
  '/zaimy/na-kartu-kukuruza-online/',
  '/zaimy/na-kartu-maestro/',
  '/zaimy/na-kartu-mastercard/',
  '/zaimy/na-kartu-mgnovenno-kruglosutochno/',
  '/zaimy/na-kartu-mgnovenno-pod-0-procentov/',
  '/zaimy/na-kartu-mir/',
  '/zaimy/na-kartu-momentum/',
  '/zaimy/na-kartu-s-18-let-online/',
  '/zaimy/na-kartu-s-nulevym-balansom/',
  '/zaimy/na-kartu-sberbanka-bezotkazno/',
  '/zaimy/na-kartu-sberbanka-do-zarplaty/',
  '/zaimy/na-kartu-sberbanka/',
  '/zaimy/na-kartu-tinkoff/',
  '/zaimy/na-kartu-visa/',
  '/zaimy/na-kartu-vtb/',
  '/zaimy/na-kartu-za-5-minut/',
  '/zaimy/na-kivi-bez-karty/',
  '/zaimy/na-kivi-bez-otkazov/',
  '/zaimy/na-kivi-bez-pasporta/',
  '/zaimy/na-kivi-bez-procentov/',
  '/zaimy/na-kivi-bez-zvonkov/',
  '/zaimy/na-kivi-pensioneram/',
  '/zaimy/na-kivi-po-pasportu/',
  '/zaimy/na-kivi-s-18-let/',
  '/zaimy/na-kivi-s-plohoy-ki/',
  '/zaimy/na-kivi-virtualnuju-kartu/',
  '/zaimy/na-kivi/',
  '/zaimy/na-kreditnuju-kartu/',
  '/zaimy/na-neimennuyu-kartu/',
  '/zaimy/na-polgoda-6-mesyacev/',
  '/zaimy/na-sberknizhku/',
  '/zaimy/na-telefon/',
  '/zaimy/na-virtualnuyu-kartu/',
  '/zaimy/na-yandex-bez-privjazki-karty/',
  '/zaimy/na-yandex/',
  '/zaimy/na-zolotuyu-koronu/',
  '/zaimy/nalichnymi/',
  '/zaimy/ne-vyhodja-iz-doma/',
  '/zaimy/nochju/',
  '/zaimy/novye-mfo/',
  '/zaimy/novye/',
  '/zaimy/pensioneram/',
  '/zaimy/perevodom/',
  '/zaimy/po-pasportu/',
  '/zaimy/po-sms/',
  '/zaimy/po-telefonu/',
  '/zaimy/pod-0-protsentov/',
  '/zaimy/pod-nizkij-procent/',
  '/zaimy/pod-zalog-avtomobilya-s-pravom-polzovaniya/',
  '/zaimy/pod-zalog-documentov/',
  '/zaimy/pod-zalog-gruzovogo-avtomobilja/',
  '/zaimy/pod-zalog-motocykla/',
  '/zaimy/pod-zalog-nedvizhimosti/',
  '/zaimy/pod-zalog-spectehniki/',
  '/zaimy/refinansirovanie/',
  '/zaimy/robot/',
  '/zaimy/s-19-let-na-kartu/',
  '/zaimy/s-21-goda/',
  '/zaimy/s-avtomaticheskim-odobreniem-na-kartu-online/',
  '/zaimy/s-chernym-spiskom/',
  '/zaimy/s-ezhemesyachnym-platezhom/',
  '/zaimy/s-ljuboy-ki/',
  '/zaimy/s-plokhoj-kreditnoj-istoriej/',
  '/zaimy/s-prosrochkami/',
  '/zaimy/servis/',
  '/zaimy/srochno-100/',
  '/zaimy/srochno-1000/',
  '/zaimy/srochno-10000/',
  '/zaimy/srochno-100000/',
  '/zaimy/srochno-1000000/',
  '/zaimy/srochno-12000/',
  '/zaimy/srochno-1500/',
  '/zaimy/srochno-15000/',
  '/zaimy/srochno-150000/',
  '/zaimy/srochno-2000/',
  '/zaimy/srochno-20000/',
  '/zaimy/srochno-200000/',
  '/zaimy/srochno-25000/',
  '/zaimy/srochno-250000/',
  '/zaimy/srochno-3000/',
  '/zaimy/srochno-30000/',
  '/zaimy/srochno-300000/',
  '/zaimy/srochno-35000/',
  '/zaimy/srochno-4000/',
  '/zaimy/srochno-40000/',
  '/zaimy/srochno-500/',
  '/zaimy/srochno-5000/',
  '/zaimy/srochno-50000/',
  '/zaimy/srochno-500000/',
  '/zaimy/srochno-6000/',
  '/zaimy/srochno-60000/',
  '/zaimy/srochno-7000/',
  '/zaimy/srochno-70000/',
  '/zaimy/srochno-8000/',
  '/zaimy/srochno-80000/',
  '/zaimy/srochno-9000/',
  '/zaimy/srochno-90000/',
  '/zaimy/studentam-na-kartu-bez-otkaza/',
  '/zaimy/top/',
  '/zaimy/uluchshenie-ki/',
  '/zaimy/v-dolg-na-kartu/',
  '/zaimy/v-dolg-na-yandex/',
  '/zaimy/v-dolg/',
  '/zaimy/voennosluzhashhim/',
  '/zaimy/vse-produkty/',
  '/zaimy/vsem/',
  '/zaimy/za-15-minut/',
  '/zaimy/za-chas/',
  '/zaimy/za-minutu/',
  '/zaimy/zayavka-vo-vse-mikrozaimy-srazu/',
  '/zaimy/pervyj-bez-protsentov/',
  '/zaimy/na-yumoney/',
  '/zaimy/bez-poseshcheniya-ofisa/',
  '/zaimy/online/',
  '/zaimy/onlain/',
  '/zaimy/srazu/',
  '/zaimy/pod-raspisku/',
  '/zaimy/bez-zaloga/',
  '/zaimy/bez-ukazanija-raboti/',
  '/zaimy/na-5-let/',
  '/zaimy/po-vsej-rossii/',
  '/zaimy/bystriy-na-kartu/',
  '/zaimy/mikrokredit-na-kartu-onlayn/',
  '/zaimy/na-noviy-god/',
  '/zaimy/proverennyye-zaymy-do-30-000/',
  '/zaimy/novye-zaymy/',
  '/zaimy/novye-mfo-i-mkk/',
  '/zaimy/novye-zaymy-s-plohoy-kreditnoy-istoriey/',
  '/zaimy/novye-zaymy-bez-proverok/',
  '/zaimy/novye-mfo-i-mkk-bez-proverok/',
  '/zaimy/novye-mfo-i-mkk-bez-otkaza/',
  '/zaimy/novye-zaymy-bez-otkaza/',
  '/zaimy/zaymy-v-maloizvestnyh-mfo/',
  '/zaimy/novye-mfo-i-mkk-s-prosrochkami-srochno/',
  '/zaimy/novye-mfo-i-mkk-s-plohoy-kreditnoy-istoriey/',
  '/zaimy/novye-zaymy-kruglosutochno/',
  '/zaimy/novye-mfo-i-mkk-s-vysokim-odobreniem/',
  '/zaimy/zaymy-v-maloizvestnyh-mfo-bez-otkaza/',
  '/zaimy/novye-zaymy-s-vysokim-odobreniem/',
  '/zaimy/novye-zaymy-bez-otkaza-srochno/',
  '/zaimy/novye-zaymy-bez-procentov/',
  '/zaimy/novye-zaymy-s-prosrochkami/',
  '/zaimy/novye-mfo-i-mkk-kruglosutochnobez-otkaza/',
  '/zaimy/novye-zaymy-bez-otkaza-mgnovenno/',
  '/zaimy/novye-zaymy-cherez-gosuslugi/',
  '/zaimy/novye-mfo-i-mkk-dlya-pensionerov/',
  '/zaimy/novye-mfo-i-mkk-mgnovenno/',
  '/zaimy/novye-mfo-i-mkk-s-prosrochkami/',
  '/zaimy/zaymy-v-maloizvestnyh-mfo-srochno/',
  '/zaimy/novye-mfo-i-mkk-bez-zvonkov/',
  '/zaimy/novye-mfo-i-mkk-bez-procentov/',
  '/zaimy/novye-mfo-i-mkk-bez-spravok/',
  '/zaimy/novye-mfo-i-mkk-dolgosrochnye/',
  '/zaimy/novye-mfo-i-mkk-kruglosutochno/',
  '/zaimy/novye-mfo-i-mkk-na-10-dney/',
  '/zaimy/novye-mfo-i-mkk-na-15-dney/',
  '/zaimy/novye-mfo-i-mkk-na-20-dney/',
  '/zaimy/novye-mfo-i-mkk-na-30-dney/',
  '/zaimy/novye-mfo-i-mkk-na-bolshie-summy/',
  '/zaimy/novye-mfo-i-mkk-s-nizkoy-procentnoy-stavkoy/',
  '/zaimy/novye-mfo-i-mkk-po-pasportu/',
  '/zaimy/novye-mfo-i-mkk-s-kreditami/',
  '/zaimy/novye-mfo-i-mkk-cherez-gosuslugi/',
  '/zaimy/srochniy/',
];

export const seoListRegionRoutes: readonly string[] = [
  '/zaimy/24-chasa-onlayn/:paramRegion/',
  '/zaimy/besplatno/:paramRegion/',
  '/zaimy/bez-foto/:paramRegion/',
  '/zaimy/bez-karty/:paramRegion/',
  '/zaimy/bez-otkaza-100-procentov-odobreniya/:paramRegion/',
  '/zaimy/bez-otkaza/:paramRegion/',
  '/zaimy/bez-pasporta/:paramRegion/',
  '/zaimy/bez-propiski-i-vremennoy-registracii/:paramRegion/',
  '/zaimy/bez-protsentov/:paramRegion/',
  '/zaimy/bez-proverok/:paramRegion/',
  '/zaimy/bezrabotnym/:paramRegion/',
  '/zaimy/dengi-v-dolg-na-kivi/:paramRegion/',
  '/zaimy/dolgosrochnye/:paramRegion/',
  '/zaimy/ehkspress-na-kartu/:paramRegion/',
  '/zaimy/kruglosutochno/:paramRegion/',
  '/zaimy/maloizvestnye-mfo/:paramRegion/',
  '/zaimy/mikro/:paramRegion/',
  '/zaimy/mikrozaymy-dlya-biznesa/:paramRegion/',
  '/zaimy/mini-onlajn/:paramRegion/',
  '/zaimy/momentalny/:paramRegion/',
  '/zaimy/na-1-mesjac/:paramRegion/',
  '/zaimy/na-bankovskiy-schet/:paramRegion/',
  '/zaimy/na-contact/:paramRegion/',
  '/zaimy/na-dom/:paramRegion/',
  '/zaimy/na-elektronnyy-koshelek/:paramRegion/',
  '/zaimy/na-god-12-mesyacev/:paramRegion/',
  '/zaimy/na-kartu-bez-otkazov/:paramRegion/',
  '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/:paramRegion/',
  '/zaimy/na-kartu-bez-proverok-i-zvonkov/:paramRegion/',
  '/zaimy/na-kartu-do-zarplaty/:paramRegion/',
  '/zaimy/na-kartu-maestro/:paramRegion/',
  '/zaimy/na-kartu-mgnovenno-kruglosutochno/:paramRegion/',
  '/zaimy/na-kartu-mir/:paramRegion/',
  '/zaimy/na-kartu-s-18-let-online/:paramRegion/',
  '/zaimy/na-kartu-sberbanka/:paramRegion/',
  '/zaimy/na-kartu-za-5-minut/:paramRegion/',
  '/zaimy/na-kivi-bez-karty/:paramRegion/',
  '/zaimy/na-kivi-bez-otkazov/:paramRegion/',
  '/zaimy/na-kivi/:paramRegion/',
  '/zaimy/na-polgoda-6-mesyacev/:paramRegion/',
  '/zaimy/na-yandex/:paramRegion/',
  '/zaimy/na-zolotuyu-koronu/:paramRegion/',
  '/zaimy/nalichnymi/:paramRegion/',
  '/zaimy/novye/:paramRegion/',
  '/zaimy/pensioneram/:paramRegion/',
  '/zaimy/po-pasportu/:paramRegion/',
  '/zaimy/po-telefonu/:paramRegion/',
  '/zaimy/pod-0-protsentov/:paramRegion/',
  '/zaimy/pod-nizkij-procent/:paramRegion/',
  '/zaimy/pod-zalog-gruzovogo-avtomobilja/:paramRegion/',
  '/zaimy/s-19-let-na-kartu/:paramRegion/',
  '/zaimy/s-ezhemesyachnym-platezhom/:paramRegion/',
  '/zaimy/s-plokhoj-kreditnoj-istoriej/:paramRegion/',
  '/zaimy/s-prosrochkami/:paramRegion/',
  '/zaimy/srochno-1000/:paramRegion/',
  '/zaimy/srochno-10000/:paramRegion/',
  '/zaimy/srochno-100000/:paramRegion/',
  '/zaimy/srochno-15000/:paramRegion/',
  '/zaimy/srochno-2000/:paramRegion/',
  '/zaimy/srochno-20000/:paramRegion/',
  '/zaimy/srochno-3000/:paramRegion/',
  '/zaimy/srochno-30000/:paramRegion/',
  '/zaimy/srochno-40000/:paramRegion/',
  '/zaimy/srochno-5000/:paramRegion/',
  '/zaimy/srochno-50000/:paramRegion/',
  '/zaimy/srochno-60000/:paramRegion/',
  '/zaimy/srochno-70000/:paramRegion/',
  '/zaimy/srochno-80000/:paramRegion/',
  '/zaimy/studentam-na-kartu-bez-otkaza/:paramRegion/',
  '/zaimy/top/:paramRegion/',
  '/zaimy/v-dolg-na-kartu/:paramRegion/',
  '/zaimy/v-dolg/:paramRegion/',
  '/zaimy/online/:paramRegion/',
];

export const kazakhstanSeoListRoutes: readonly string[] = [
  '/kz/zaimy/',
  '/kz/zaimy/mikrokredit-do-2-mln/',
  '/kz/zaimy/mikrokredit-dlya-ip/',
  '/kz/zaimy/dlya-ispravleniya-kreditnoy-istorii/',
  '/kz/zaimy/na-noviy-god/',
  '/kz/zaimy/povtorniy/',
  '/kz/zaimy/dengi-v-dolg-bez-zaloga/',
  '/kz/zaimy/maloizvestnyye-mfo-kazakhstan/',
  '/kz/zaimy/korona/',
  '/kz/zaimy/narodniy/',
  '/kz/zaimy/legkiye/',
  '/kz/zaimy/besplatnyye/',
  '/kz/zaimy/dengi-v-dolg-bez-otkaza/',
  '/kz/zaimy/dengi-v-dolg-bez-pasporta/',
  '/kz/zaimy/dengi-v-dolg-bez-proverki-kreditnoy-istorii/',
  '/kz/zaimy/dengi-v-dolg-na-kivi/',
  '/kz/zaimy/dengi-v-dolg-s-plokhoy-kreditnoy-istoriyey/',
  '/kz/zaimy/dengi-v-rassrochku-bez-protsentov/',
  '/kz/zaimy/na-10-dney/',
  '/kz/zaimy/na-1-mesyats/',
  '/kz/zaimy/na-2-goda/',
  '/kz/zaimy/na-2-mesyatsa/',
  '/kz/zaimy/na-3-goda/',
  '/kz/zaimy/na-3-mesyatsa/',
  '/kz/zaimy/na-4-mesyatsa/',
  '/kz/zaimy/na-5-let/',
  '/kz/zaimy/na-60-dney/',
  '/kz/zaimy/na-180-dney/',
  '/kz/zaimy/do-zp/',
  '/kz/zaimy/dolgosrochniy-na-kartu-onlayn-s-ezhemesyachnym-pogasheniyem/',
  '/kz/zaimy/na-neskolko-mesyatsev/',
  '/kz/zaimy/na-nedelyu/',
  '/kz/zaimy/na-polgoda/',
  '/kz/zaimy/na-god/',
  '/kz/zaimy/bez-protsentov/',
  '/kz/zaimy/bez-komissii/',
  '/kz/zaimy/bez-strakhovki/',
  '/kz/zaimy/v-dekretnom-otpuske/',
  '/kz/zaimy/bez-protsentov-na-30-dney/',
  '/kz/zaimy/po-telefonu-kruglosutochno/',
  '/kz/zaimy/na-nomer-telefona/',
  '/kz/zaimy/po-telefonu/',
  '/kz/zaimy/pod-protsenty/',
  '/kz/zaimy/pod-materinskiy-kapital/',
  '/kz/zaimy/na-remont/',
  '/kz/zaimy/na-stroitelstvo-doma/',
  '/kz/zaimy/na-obucheniye/',
  '/kz/zaimy/bez-otkaza/',
  '/kz/zaimy/bez-otkaza-s-plokhoy-kreditnoy-istoriyey/',
  '/kz/zaimy/bez-proverki-kreditnoy-istorii/',
  '/kz/zaimy/srazu/',
  '/kz/zaimy/247/',
  '/kz/zaimy/bez-poseshcheniya-ofisa/',
  '/kz/zaimy/bez-voprosov/',
  '/kz/zaimy/bez-zvonkov/',
  '/kz/zaimy/bez-verifikatsii/',
  '/kz/zaimy/dosrochniy/',
  '/kz/zaimy/mgnovenniy/',
  '/kz/zaimy/srochniy-na-kartu/',
  '/kz/zaimy/srochnyye-onlayn/',
  '/kz/zaimy/s-100-odobreniyem-bez-otkaza/',
  '/kz/zaimy/bez-otkaza-i-proverok/',
  '/kz/zaimy/bezotkaznyye/',
  '/kz/zaimy/bez-podpisok/',
  '/kz/zaimy/bez-posrednikov/',
  '/kz/zaimy/bez-predoplaty/',
  '/kz/zaimy/bez-propiski/',
  '/kz/zaimy/bez-proverok/',
  '/kz/zaimy/bez-registratsii/',
  '/kz/zaimy/po-iin/',
  '/kz/zaimy/bez-spisaniya/',
  '/kz/zaimy/bez-spravok-o-dokhodakh/',
  '/kz/zaimy/bez-telefona/',
  '/kz/zaimy/bez-ukazaniya-raboty/',
  '/kz/zaimy/bez-podtverzhdeniya-karty/',
  '/kz/zaimy/bez-podtverzhdeniya-lichnosti/',
  '/kz/zaimy/bez-pasporta/',
  '/kz/zaimy/s-prosrochkami/',
  '/kz/zaimy/s-vremennoy-registratsiyey/',
  '/kz/zaimy/na-kartu-s-poruchitelem/',
  '/kz/zaimy/na-kartu-s-otritsatelnym-balansom/',
  '/kz/zaimy/na-kartu-s-prosrochennym-pasportom/',
  '/kz/zaimy/na-kartu-maestro/',
  '/kz/zaimy/500000-tenge/',
  '/kz/zaimy/10000-tenge/',
  '/kz/zaimy/300000-tenge/',
  '/kz/zaimy/5000-tg/',
  '/kz/zaimy/2000-tenge/',
  '/kz/zaimy/35000-tenge/',
  '/kz/zaimy/400000-tenge/',
  '/kz/zaimy/100000-tenge/',
  '/kz/zaimy/50000-/',
  '/kz/zaimy/60000-na-kartu/',
  '/kz/zaimy/30000-/',
  '/kz/zaimy/200-tysyach/',
  '/kz/zaimy/15-tysyach/',
  '/kz/zaimy/mikrokredit-dlya-biznesa/',
  '/kz/zaimy/dlya-ip/',
  '/kz/zaimy/dlya-ooo/',
  '/kz/zaimy/vzyat-v-lombarde/',
  '/kz/zaimy/vzyat-na-kreditnuyu-kartu-bez-otkaza/',
  '/kz/zaimy/chastniy-pri-lichnoy-vstreche-almaty/',
  '/kz/zaimy/vzyat-na-virtualnuyu-kartu/',
  '/kz/zaimy/vzyat-bez-nomera-telefona/',
  '/kz/zaimy/vzyat-bez-platnykh-podpisok/',
  '/kz/zaimy/vzyat-bez-spisaniya-s-karty/',
  '/kz/zaimy/bez-pensionki/',
  '/kz/zaimy/bez-ukazaniya-mesta-raboty/',
  '/kz/zaimy/bez-podbora-napryamuyu-bez-otkaza/',
  '/kz/zaimy/dlya-voyennosluzhashchikh/',
  '/kz/zaimy/dlya-vsekh/',
  '/kz/zaimy/vse/',
  '/kz/zaimy/dlya-naseleniya/',
  '/kz/zaimy/dlya-fizicheskikh-lits/',
  '/kz/zaimy/dlya-inostrantsev/',
  '/kz/zaimy/dlya-yuridicheskikh-lits/',
  '/kz/zaimy/na-bankovskiy-schet/',
  '/kz/zaimy/na-chuzhuyu-kartu/',
  '/kz/zaimy/na-debetovuyu-kartu/',
  '/kz/zaimy/na-dom/',
  '/kz/zaimy/na-elektronniy-koshelek/',
  '/kz/zaimy/na-kartu/',
  '/kz/zaimy/na-kartu-bez-otkaza-i-proverok/',
  '/kz/zaimy/na-kartu-bez-otkazov/',
  '/kz/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/',
  '/kz/zaimy/na-kartu-bez-proverok-i-zvonkov/',
  '/kz/zaimy/na-kartu-do-zarplaty/',
  '/kz/zaimy/na-kartu-kukuruza-onlayn/',
  '/kz/zaimy/na-kartu-maestro-dlya-pensionerov/',
  '/kz/zaimy/na-kartu-mastercard/',
  '/kz/zaimy/mgnovennyye-kruglosutochnyye-y-na-kartu/',
  '/kz/zaimy/mgnovennyye-y-na-kartu-pod-0-protsentov/',
  '/kz/zaimy/na-kartu-mir/',
  '/kz/zaimy/na-kartu-momentum/',
  '/kz/zaimy/na-kartu-s-18-let-onlayn/',
  '/kz/zaimy/na-kartu-sberbanka/',
  '/kz/zaimy/na-kartu-sberbanka-bezotkazno/',
  '/kz/zaimy/na-kartu-sberbanka-do-zarplaty/',
  '/kz/zaimy/na-kartu-s-nulevym-balansom/',
  '/kz/zaimy/samyye-novyye-y-na-kartu/',
  '/kz/zaimy/na-kartu-s-plokhoy-kreditnoy-istoriyey/',
  '/kz/zaimy/na-kartu-visa/',
  '/kz/zaimy/na-kartu-vtb/',
  '/kz/zaimy/na-kartu-za-5-minut/',
  '/kz/zaimy/na-kivi/',
  '/kz/zaimy/na-kivi-bez-karty/',
  '/kz/zaimy/na-kivi-bez-otkazov/',
  '/kz/zaimy/na-kivi-bez-pasporta/',
  '/kz/zaimy/na-kivi-bez-protsentov/',
  '/kz/zaimy/na-kivi-bez-proverki-kreditnoy-istorii/',
  '/kz/zaimy/na-kivi-bez-zvonkov/',
  '/kz/zaimy/na-kivi-dlya-pensionerov/',
  '/kz/zaimy/na-kivi-po-pasportu/',
  '/kz/zaimy/na-kivi-s-18-let/',
  '/kz/zaimy/na-kivi-s-plokhoy-kreditnoy-istoriyey/',
  '/kz/zaimy/na-kivi-virtualnuyu-kartu/',
  '/kz/zaimy/na-kreditnuyu-kartu/',
  '/kz/zaimy/na-virtualnuyu-kartu/',
  '/kz/zaimy/na-neimennuyu-kartu/',
  '/kz/zaimy/na-sberknizhku/',
  '/kz/zaimy/na-telefon/',
  '/kz/zaimy/na-webmoney/',
  '/kz/zaimy/na-yandeks/',
  '/kz/zaimy/na-yandeks-bez-privyazki-karty/',
  '/kz/zaimy/na-zolotuyu-koronu/',
  '/kz/zaimy/na-kartu-pri-otritsatelnom-balanse/',
  '/kz/zaimy/pod-garazh/',
  '/kz/zaimy/na-kartu-dlya-pogasheniya-a/',
  '/kz/zaimy/realnyye-y/',
  '/kz/zaimy/otzyvy-o-akh/',
  '/kz/zaimy/300-tysyach-tenge/',
  '/kz/zaimy/2000-tg/',
  '/kz/zaimy/400-tysyach-tenge/',
  '/kz/zaimy/3000-tg/',
  '/kz/zaimy/4000-tg/',
  '/kz/zaimy/6000-tg/',
  '/kz/zaimy/dolgosrochnyye/',
  '/kz/zaimy/kratkosrochnyye/',
  '/kz/zaimy/po-vsemu-kazakhstanu/',
  '/kz/zaimy/mikrokredit/',
  '/kz/zaimy/mfo-kazakhstan/',
  '/kz/zaimy/mfo-onlayn-kazakhstan/',
  '/kz/zaimy/noviy-bez-otkaza/',
  '/kz/zaimy/reyting-mfo/',
  '/kz/zaimy/s-17-let/',
  '/kz/zaimy/s-18-let/',
  '/kz/zaimy/19-let/',
  '/kz/zaimy/20-let/',
  '/kz/zaimy/21-goda/',
  '/kz/zaimy/dlya-pensionerov/',
  '/kz/zaimy/dlya-pensionerov-do-70-let/',
  '/kz/zaimy/dlya-pensionerov-do-75-let/',
  '/kz/zaimy/dlya-pensionerov-do-80-let/',
  '/kz/zaimy/zhenskiy/',
  '/kz/zaimy/dengi-v-dolg-studentu/',
  '/kz/zaimy/dlya-dolzhnikov/',
  '/kz/zaimy/bezotkazniy-na-kartu-bezrabotnym/',
  '/kz/zaimy/vzyat-bez-raboty/',
  '/kz/zaimy/pod-zalog-avto/',
  '/kz/zaimy/pod-zalog-pts/',
  '/kz/zaimy/pod-zalog-telefona/',
  '/kz/zaimy/v-lombarde-pod-zalog-zolota/',
  '/kz/zaimy/pod-zalog-nedvizhimosti/',
  '/kz/zaimy/pod-zalog-mototsikla/',
  '/kz/zaimy/pod-zalog-gruzovogo-avtomobilya/',
  '/kz/zaimy/pod-zalog-dokumentov/',
  '/kz/zaimy/pod-zalog-zemelnogo-uchastka/',
  '/kz/zaimy/pod-avto-bez-pts/',
  '/kz/zaimy/pod-zalog-doli-v-kvartire/',
  '/kz/zaimy/bystriy-pod-pts/',
  '/kz/zaimy/pod-zalog-spetstekhniki/',
  '/kz/zaimy/pod-pts-po-generalnoy-doverennosti/',
  '/kz/zaimy/ypod-zalog-avtomobilya-s-pravom-polzovaniya/',
  '/kz/zaimy/srochnyye-y-onlayn/',
  '/kz/zaimy/srochniy-na-kartu-kazakhstan/',
  '/kz/zaimy/bystriy-onlayn/',
  '/kz/zaimy/bystriy-na-kartu/',
  '/kz/zaimy/bystriy-deneg-po-pasportu/',
  '/kz/zaimy/bystriy-pod-zalog/',
  '/kz/zaimy/bystriy-na-chuzhuyu-kartu/',
  '/kz/zaimy/bystriy-bez-elektronnoy-pochty/',
  '/kz/zaimy/bystriy-ne-vykhodya-iz-doma/',
  '/kz/zaimy/bystriy-po-telefonu/',
  '/kz/zaimy/onlayn-na-kartu/',
  '/kz/zaimy/momentalniy-na-kartu-onlayn-kazakhstan/',
  '/kz/zaimy/onlayn-momentalnoye-resheniye/',
  '/kz/zaimy/na-kartu-kruglosutochno-bez-proverok/',
  '/kz/zaimy/mikro-za-minutu/',
  '/kz/zaimy/mikrokredit-onlayn-na-kartu/',
  '/kz/zaimy/mikrokredit-bystro-na-kartu/',
  '/kz/zaimy/mikro-onlayn-kazakhstan/',
  '/kz/zaimy/bot/',
  '/kz/zaimy/na-kivi-ili-yandeks/',
  '/kz/zaimy/bystriy-na-yandeks-dengi/',
  '/kz/zaimy/ycherez-gosuslugi/',
  '/kz/zaimy/onlayn-y/',
  '/kz/zaimy/onlayn-zayavka-na-y/',
  '/kz/zaimy/nalichnymi/',
  '/kz/zaimy/nalichnymi-bez-karty/',
  '/kz/zaimy/dengi-v-dolg-na-dom/',
  '/kz/zaimy/dengi-v-dolg-s-dostavkoy/',
  '/kz/zaimy/dengi-v-dolg-cherez-kazpochtu/',
  '/kz/zaimy/dengi-v-dolg-pod-raspisku/',
  '/kz/zaimy/vzyat-onlayn-perevodom/',
  '/kz/zaimy/mini/',
  '/kz/zaimy/mikrokredit-na-god/',
  '/kz/zaimy/mikrokredit-na-6-mesyatsev/',
  '/kz/zaimy/mikrokredit-na-2-mesyatsa/',
  '/kz/zaimy/mikrokredit-na-30-dney/',
  '/kz/zaimy/mikrokredit-do-1-mln-tenge/',
];

/* SELECT REGION PAGE ROUTES (вынесены из seoListRoutes) */
const selectRegionListRoutes: readonly string[] = [
  '/zaimy/vybor-regiona/',
  '/zaimy/pod-pts/vybor-regiona/',
  '/zaimy/na-kartu/vybor-regiona/',
];

export const seoSelectRegionRoutes = seoListRoutes
  .map((route) => `${route}vybor-regiona/`)
  .concat(selectRegionListRoutes);

export const generalRouteNamesMap = new Map([
  [seoListRoutes, 'seo_list_routes'],
  [seoListRegionRoutes, 'seo_list_region_routes'],
  [seoSelectRegionRoutes, 'seo_select_region_routes'],
  [podPtsListRoutes, 'pod_pts_list_routes'],
]);
